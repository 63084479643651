// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-feature-page-tsx": () => import("./../src/templates/feature-page.tsx" /* webpackChunkName: "component---src-templates-feature-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-news-index-tsx": () => import("./../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-simulator-bpmn-viewer-tsx": () => import("./../src/pages/simulator/bpmnViewer.tsx" /* webpackChunkName: "component---src-pages-simulator-bpmn-viewer-tsx" */),
  "component---src-pages-simulator-heatmap-viewer-tsx": () => import("./../src/pages/simulator/heatmapViewer.tsx" /* webpackChunkName: "component---src-pages-simulator-heatmap-viewer-tsx" */),
  "component---src-pages-simulator-index-tsx": () => import("./../src/pages/simulator/index.tsx" /* webpackChunkName: "component---src-pages-simulator-index-tsx" */),
  "component---src-pages-simulator-members-tsx": () => import("./../src/pages/simulator/members.tsx" /* webpackChunkName: "component---src-pages-simulator-members-tsx" */),
  "component---src-pages-simulator-trial-tsx": () => import("./../src/pages/simulator/trial.tsx" /* webpackChunkName: "component---src-pages-simulator-trial-tsx" */)
}

